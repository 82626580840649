/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'send-check-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M15.964.686a.5.5 0 00-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 00-.082.887l.41.26.001.002 4.995 3.178 1.59 2.498C8 14 8 13 8 12.5a4.5 4.5 0 015.026-4.47zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 00-.154-.154l-.338-.215 7.494-7.494 1.178-.471z"/><path pid="1" d="M16 12.5a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0m-1.993-1.679a.5.5 0 00-.686.172l-1.17 1.95-.547-.547a.5.5 0 00-.708.708l.774.773a.75.75 0 001.174-.144l1.335-2.226a.5.5 0 00-.172-.686"/>',
    },
});
